import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery } from 'gatsby'
import { graphql } from 'gatsby'

import LevitationCSS from './section-levitation.module.css'

const FingerLevitation = ({ fullpageApi }) => (
  <StaticQuery
    query={graphql`
      query ImageOneLevitation {
        file(
          relativePath: { eq: "projets/Labo/finger/photos/levitation.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 87) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={({ file }) => (
      <div
        className="section"
        onClick={() => fullpageApi.moveSectionDown()}
        onKeyDown={() => fullpageApi.moveSectionDown()}
        role="button"
        tabIndex={0}
        style={{ cursor: 'pointer' }}
      >
        <Img
          className={LevitationCSS.image}
          style={{ position: 'absolute' }}
          fluid={file.childImageSharp.fluid}
        />
        <div className={LevitationCSS.cercle} />
      </div>
    )}
  />
)

export default FingerLevitation
