import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../../components/layout'
import Content from '../../../components/content'
import Sidebar from '../../../components/Sidebar'
import ReactFullpage from '@fullpage/react-fullpage'
import Responsive from 'react-responsive'

import Rotate from '../../../components/custom/finger-photos/mobile-rotate'

import FingerIntro from '../../../components/custom/finger-photos/section-intro'
import FingerMonster from '../../../components/custom/finger-photos/section-monster'
import FingerLevitation from '../../../components/custom/finger-photos/section-levitation'
import FingerFlowercut from '../../../components/custom/finger-photos/section-flowercut'
import FingerVoile from '../../../components/custom/finger-photos/section-voile'
import FingerSixtine from '../../../components/custom/finger-photos/section-sixtine'
import FingerRedot from '../../../components/custom/finger-photos/section-redot'
import FingerBluegold from '../../../components/custom/finger-photos/section-bluegold'
import FingerPistol from '../../../components/custom/finger-photos/section-pistol'
import FingerMousse from '../../../components/custom/finger-photos/section-mousse'
import FingerYellowBlue from '../../../components/custom/finger-photos/section-yellowblue'
import FingerNouille from '../../../components/custom/finger-photos/section-nouille'
import FingerGreenPath from '../../../components/custom/finger-photos/section-greenpath'
import FingerKing from '../../../components/custom/finger-photos/section-king'
import FingerEnd from '../../../components/custom/finger-photos/section-end'

const Desktop = props => <Responsive {...props} minWidth={992} />
const MobilePortrait = props => (
  <Responsive {...props} orientation="portrait" maxWidth={992} />
)
const MobileLandscape = props => (
  <Responsive {...props} orientation="landscape" maxWidth={992} />
)

const anchors = [
  'intro',
  'monster',
  'sixtine',
  'redot',
  'foam',
  'yellowblue',
  'bluegold',
  'levitation',
  'nouille',
  'greenpath',
  'king',
  'pistol',
  'myst',
  'flowercut',
  'theend',
]

const fullsettings = {
  scrollingSpeed: 250,
  licenseKey: '45AC9FED-64BF46C5-80C5B053-A28FCC01',
}

const FingerPhotosPage = () => {
  return (
    <>
      <Helmet
        title="Le doigt dans l'œil, les photos - Rémi Carayon"
        bodyAttributes={{
          class: 'fingerphotos-page',
        }}
        defer={false}
      >
        <meta property="og:title" content="Le doigt dans l'œil, les photos" />
        <meta
          property="og:description"
          content="Les photos du jeu 'Le doigt dans l'œil'"
        />
      </Helmet>
      <Layout place="page">
        <Desktop>
          <Sidebar />
          <Content>
            <ReactFullpage
              anchors={anchors}
              {...fullsettings}
              render={({ fullpageApi }) => {
                return (
                  <ReactFullpage.Wrapper>
                    <FingerIntro fullpageApi={fullpageApi} />
                    <FingerMonster fullpageApi={fullpageApi} />
                    <FingerSixtine fullpageApi={fullpageApi} />
                    <FingerRedot fullpageApi={fullpageApi} />
                    <FingerMousse fullpageApi={fullpageApi} />
                    <FingerYellowBlue fullpageApi={fullpageApi} />
                    <FingerBluegold fullpageApi={fullpageApi} />
                    <FingerLevitation fullpageApi={fullpageApi} />
                    <FingerNouille fullpageApi={fullpageApi} />
                    <FingerGreenPath fullpageApi={fullpageApi} />
                    <FingerKing fullpageApi={fullpageApi} />
                    <FingerPistol fullpageApi={fullpageApi} />
                    <FingerVoile fullpageApi={fullpageApi} />
                    <FingerFlowercut fullpageApi={fullpageApi} />
                    <FingerEnd fullpageApi={fullpageApi} />
                  </ReactFullpage.Wrapper>
                )
              }}
            />
          </Content>
        </Desktop>
        <MobilePortrait>
          <Sidebar />
          <Rotate />
        </MobilePortrait>
        <MobileLandscape>
          <Content>
            <ReactFullpage
              anchors={anchors}
              render={({ fullpageApi }) => {
                return (
                  <ReactFullpage.Wrapper>
                    <FingerIntro fullpageApi={fullpageApi} />
                    <FingerMonster fullpageApi={fullpageApi} />
                    <FingerSixtine fullpageApi={fullpageApi} />
                    <FingerRedot fullpageApi={fullpageApi} />
                    <FingerMousse fullpageApi={fullpageApi} />
                    <FingerYellowBlue fullpageApi={fullpageApi} />
                    <FingerBluegold fullpageApi={fullpageApi} />
                    <FingerLevitation fullpageApi={fullpageApi} />
                    <FingerNouille fullpageApi={fullpageApi} />
                    <FingerGreenPath fullpageApi={fullpageApi} />
                    <FingerKing fullpageApi={fullpageApi} />
                    <FingerPistol fullpageApi={fullpageApi} />
                    <FingerVoile fullpageApi={fullpageApi} />
                    <FingerFlowercut fullpageApi={fullpageApi} />
                    <FingerEnd fullpageApi={fullpageApi} />
                  </ReactFullpage.Wrapper>
                )
              }}
            />
          </Content>
        </MobileLandscape>
      </Layout>
    </>
  )
}

export default FingerPhotosPage
