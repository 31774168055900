import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery } from 'gatsby'
import { graphql } from 'gatsby'

import FlowercutCSS from './section-flowercut.module.css'

const FingerFlowercut = ({ fullpageApi }) => (
  <StaticQuery
    query={graphql`
      query ImageOneFlowercut {
        file(relativePath: { eq: "projets/Labo/finger/photos/flowercut.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2120, quality: 87) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={({ file }) => (
      <div
        className="section"
        onClick={() => fullpageApi.moveSectionDown()}
        onKeyDown={() => fullpageApi.moveSectionDown()}
        role="button"
        tabIndex={0}
        style={{ cursor: 'pointer' }}
      >
        <Img
          imgStyle={{ objectFit: 'contain' }}
          className={FlowercutCSS.image}
          fluid={file.childImageSharp.fluid}
        />
      </div>
    )}
  />
)

export default FingerFlowercut
