import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery } from 'gatsby'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'

import EndCSS from './section-end.module.css'

const FingerEnd = ({ fullpageApi }) => (
  <StaticQuery
    query={graphql`
      query ImageOneEnd {
        file(
          relativePath: { eq: "projets/Labo/finger/photos/finger-end.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2240, quality: 87) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={({ file }) => (
      <div className="section">
        <Img className={EndCSS.image} fluid={file.childImageSharp.fluid} />
        <Link className={EndCSS.retour} to={'/labo/finger/'}>
          retour au jeu
        </Link>
      </div>
    )}
  />
)

export default FingerEnd
