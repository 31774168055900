import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery } from 'gatsby'
import { graphql } from 'gatsby'

import IntroCSS from './section-intro.module.css'

const FingerIntro = ({ fullpageApi }) => (
  <StaticQuery
    query={graphql`
      query ImageOneIntro {
        file(
          relativePath: { eq: "projets/Labo/finger/photos/finger-intro.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1400, quality: 87) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={({ file }) => (
      <div
        className="section"
        onClick={() => fullpageApi.moveSectionDown()}
        onKeyDown={() => fullpageApi.moveSectionDown()}
        role="button"
        tabIndex={0}
        style={{ cursor: 'pointer' }}
      >
        <div>
          <Img
            className={IntroCSS.image}
            imgStyle={{ objectPosition: 'bottom right', objectFit: 'contain' }}
            fluid={file.childImageSharp.fluid}
          />
        </div>
        <h1 className={IntroCSS.title}>Le doigt dans l'œil</h1>
        <svg className={IntroCSS.arrows}>
          <path className={IntroCSS.a1} d="M0 0 L30 32 L60 0" />
          <path className={IntroCSS.a2} d="M0 20 L30 52 L60 20" />
          <path className={IntroCSS.a3} d="M0 40 L30 72 L60 40" />
        </svg>
      </div>
    )}
  />
)

export default FingerIntro
