import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery } from 'gatsby'
import { graphql } from 'gatsby'

import VoileCSS from './section-voile.module.css'

const FingerVoile = ({ fullpageApi }) => (
  <StaticQuery
    query={graphql`
      query ImageOneVoile {
        file(relativePath: { eq: "projets/Labo/finger/photos/voile.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1400, quality: 87) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={({ file }) => (
      <div
        className="section"
        onClick={() => fullpageApi.moveSectionDown()}
        onKeyDown={() => fullpageApi.moveSectionDown()}
        role="button"
        tabIndex={0}
        style={{ cursor: 'pointer' }}
      >
        <Img
          className={VoileCSS.image}
          style={{ position: 'absolute' }}
          imgStyle={{ objectPosition: 'top right', objectFit: 'contain' }}
          fluid={file.childImageSharp.fluid}
        />
      </div>
    )}
  />
)

export default FingerVoile
