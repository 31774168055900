import React from 'react'
import PistolCSS from './section-pistol.module.css'

import PistolGif from '../../../data/projets/Labo/finger/photos/pistol.gif'

const FingerPistol = ({ fullpageApi }) => {
  return (
    <div
      className="section"
      onClick={() => fullpageApi.moveSectionDown()}
      onKeyDown={() => fullpageApi.moveSectionDown()}
      role="button"
      tabIndex={0}
      style={{ cursor: 'pointer' }}
    >
      <img className={PistolCSS.gif} alt="Finger Monster Gif" src={PistolGif} />
    </div>
  )
}

export default FingerPistol
