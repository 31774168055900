import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery } from 'gatsby'
import { graphql } from 'gatsby'

import MonsterGif from '../../../data/projets/Labo/finger/photos/monster.gif'

import MonsterCSS from './section-monster.module.css'

const FingerMonster = ({ fullpageApi }) => (
  <StaticQuery
    query={graphql`
      query ImageOneMonster {
        file(relativePath: { eq: "projets/Labo/finger/photos/monster.jpg" }) {
          childImageSharp {
            fluid(maxHeight: 1600, quality: 87) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={({ file }) => (
      <div
        className="section"
        onClick={() => fullpageApi.moveSectionDown()}
        onKeyDown={() => fullpageApi.moveSectionDown()}
        role="button"
        tabIndex={0}
        style={{ cursor: 'pointer' }}
      >
        <Img className={MonsterCSS.jpg} fluid={file.childImageSharp.fluid} />
        <img
          className={MonsterCSS.gif}
          alt="Finger Monster Gif"
          src={MonsterGif}
        />
        <div className={`${MonsterCSS.l} ${MonsterCSS.cercle}`} />
        <div className={`${MonsterCSS.m} ${MonsterCSS.cercle}`} />
        <div className={`${MonsterCSS.s} ${MonsterCSS.cercle}`} />
      </div>
    )}
  />
)

export default FingerMonster
