import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery } from 'gatsby'
import { graphql } from 'gatsby'

import YellowBlueCSS from './section-yellowblue.module.css'

const FingerYellowBlue = ({ fullpageApi }) => (
  <StaticQuery
    query={graphql`
      query ImageOneYellowBlue {
        file(
          relativePath: {
            eq: "projets/Labo/finger/photos/finger-yellowblue.jpg"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 2240, quality: 87) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={({ file }) => (
      <div
        className="section"
        onClick={() => fullpageApi.moveSectionDown()}
        onKeyDown={() => fullpageApi.moveSectionDown()}
        role="button"
        tabIndex={0}
        style={{ cursor: 'pointer' }}
      >
        <Img
          className={YellowBlueCSS.image}
          imgStyle={{ objectPosition: 'center center', objectFit: 'contain' }}
          fluid={file.childImageSharp.fluid}
        />
      </div>
    )}
  />
)

export default FingerYellowBlue
