import React from 'react'
import BluegoldCSS from './section-bluegold.module.css'

import BlueGoldGif from '../../../data/projets/Labo/finger/photos/bluegold.gif'

const FingerBluegold = ({ fullpageApi }) => {
  return (
    <div
      className="section"
      onClick={() => fullpageApi.moveSectionDown()}
      onKeyDown={() => fullpageApi.moveSectionDown()}
      role="button"
      tabIndex={0}
      style={{ cursor: 'pointer' }}
    >
      <img
        className={BluegoldCSS.gif}
        alt="Finger Monster Gif"
        src={BlueGoldGif}
      />
    </div>
  )
}

export default FingerBluegold
