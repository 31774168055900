import React from 'react'

import RedotCSS from './section-redot.module.css'

import RedotGif1 from '../../../data/projets/Labo/finger/photos/redot1.gif'
import RedotGif2 from '../../../data/projets/Labo/finger/photos/redot2.gif'
import RedotGif3 from '../../../data/projets/Labo/finger/photos/redot3.gif'
import RedotGif4 from '../../../data/projets/Labo/finger/photos/redot4.gif'
import RedotGif5 from '../../../data/projets/Labo/finger/photos/redot5.gif'
import RedotGif6 from '../../../data/projets/Labo/finger/photos/redot6.gif'

const FingerRedot = ({ fullpageApi }) => {
  return (
    <div
      className="section"
      onClick={() => fullpageApi.moveSectionDown()}
      onKeyDown={() => fullpageApi.moveSectionDown()}
      role="button"
      tabIndex={0}
      style={{ cursor: 'pointer' }}
    >
      <div className={RedotCSS.wrap}>
        <div className={RedotCSS.boxdot}>
          <div className={RedotCSS.dot} />
        </div>
        <div className={RedotCSS.boxdot}>
          <div className={RedotCSS.dot} />
        </div>
        <div className={RedotCSS.boxdot}>
          <div className={RedotCSS.dot} />
        </div>
        <div className={RedotCSS.boxdot}>
          <div className={RedotCSS.dot} />
        </div>
        <div className={RedotCSS.boxdot}>
          <div className={RedotCSS.dot} />
        </div>
        <div className={RedotCSS.boxdot}>
          <div className={RedotCSS.dot} />
        </div>
        <img
          className={RedotCSS.gif}
          alt="Finger Monster Gif"
          src={RedotGif1}
        />
        <img
          className={RedotCSS.gif}
          alt="Finger Monster Gif"
          src={RedotGif2}
        />
        <img
          className={RedotCSS.gif}
          alt="Finger Monster Gif"
          src={RedotGif3}
        />
        <img
          className={RedotCSS.gif}
          alt="Finger Monster Gif"
          src={RedotGif4}
        />
        <img
          className={RedotCSS.gif}
          alt="Finger Monster Gif"
          src={RedotGif5}
        />
        <img
          className={RedotCSS.gif}
          alt="Finger Monster Gif"
          src={RedotGif6}
        />
      </div>
    </div>
  )
}

export default FingerRedot
