import React from 'react'
import FingerRotateCSS from './mobile-rotate.module.css'

const FingerTitle = () => (
  <div class={FingerRotateCSS.rotate}>
    <div class={FingerRotateCSS.phone} />
  </div>
)

export default FingerTitle
